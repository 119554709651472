@import "../mixins/grid-framework";
@import "../mixins/grid";

// 隱藏文字
@mixin txt-hide {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 101%;
  padding: 0;
  background-repeat: no-repeat;
}
// 文字限制單行
@mixin one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// 文字限制多行行數
@mixin multi-line($line: 2, $line-height: $line-height-base, $fixed-height: false) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: ($line-height * $line) + 0em;
  @if $fixed-height {
    height: ($line-height * $line) + 0em;
  }
}
// 輸入欄位預設文字樣式
@mixin field-placeholder {
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
}
// 選取的反白樣式
@mixin selection {
  &::-moz-selection { @content; }
  &::selection { @content; }
}
// 清除浮動
@mixin clearfix {
  &::after {
    content: '';
    display: block;
    clear: both;
   }
}
// reset ul/ol樣式
@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
// reset 按鈕樣式
@mixin btn-reset {
  display: inline-block;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  box-sizing: border-box;
  border: 0;
  color: inherit;
  font: inherit;
  text-decoration: none;
  background: none;
  padding: 0;
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  &:focus {
    outline: none;
  }
}
@mixin table-reset {
  border-collapse: collapse;
  border-spacing: 0;
}
@mixin fz($size, $base: $font-size-base) {
  font-size: ($size / $base) + 0em;
}
@mixin select-caret($bg, $bg-pos: 100% 100%) {
  appearance:none;
  background: url($bg) no-repeat #{$bg-pos};
  &:focus {
    background-position: 100% 100%;
  }
}
@mixin icon-font {
  font-family: 'w19012_ic' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
