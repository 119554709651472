.sect {
    position: relative;
    &-heading {
        &-wrap {
            text-align: left;
            position: relative;
            z-index: 10;
            max-width: 1330px;
            margin: 0 auto 2em;
            .deco,
            .location {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-width: none;
            }
        }
        display: inline-block;
        vertical-align: middle;
        font-size: 1em;
        line-height: 1;
        margin: 0;
        img {
            max-height: 2em;
        }
    }
    .container {
        width: percentage($number: 275 / 375);
        padding: 4.5em 0;
    }
}
.bg1 {
    background-color: $color1;
}
.bg2 {
    background-color: $color2;
}
.bg3 {
    background-color: $color3;
}
.bg4 {
    background-color: $color4;
}
.deco {
    position: absolute;
    z-index: 5;
    pointer-events: none;
}
.banner-sect {
    .container {
        padding: 0 !important;
        width: auto;
    }
    .scroll {
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,65%);
    }
}
.banner-pic {
    margin: 0;
    img {
        width: 100%;
    }
    &.pc {
        display: none;
    }
    &.m {
        margin: 0;
    }
}
.news-sect {
    padding-top: 3em;
}
.news-slider {
    margin: 0 -0.75em;
    .item {
        padding: 0 0.75em;
    }
    .slick-prev,
    .slick-next {
        @include btn-reset;
        @include txt-hide;
        background-image: url('../img/slider_arrows.svg');
        background-size: 200% 200%;
        width: 2.8em;
        height: 2.8em;
        border-radius: 50%;
        position: absolute;
        z-index: 10;
        top: 50%;
        margin-top: -1.4em;
    }
    .slick-prev {
        left: -2.25em;
        background-position: 0 0;
        &:hover {
            background-position: 0 100%;
        }
    }
    .slick-next {
        right: -2.25em;
        background-position: 100% 0;
        &:hover {
            background-position: 100% 100%;
        }
    }
}
.news-box {
    background: $color1;
    padding: 26px 24px;
    max-width: 420px;
    margin: 0 auto;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .pic {
    }
    .txt {
        background: #fff;
        padding: 1em;
    }
    .title {
        @include fz(16px);
        font-weight: 500;
        line-height: (26 / 16);
        background: url('../img/news_border.png') no-repeat 0 100%;
        background-size: auto 4px;
        margin: 0 0 0.5em;
        padding-bottom: 0.75em;
        a {
            @include multi-line(2);
        }
    }
    .desc {
        @include multi-line(3);
        margin-bottom: 0;
    }
}
.about-sect {
    padding-top: 2em;
    .deco1 {
        width: 70px;
        top: 2em;
        right: percentage($number: 25 / 375);
        transform: rotate(20deg);
    }
    .deco2, .deco3 {
        display: none;
    }
}
.about-content {
    position: relative;
    @include fz(15px);
    color: #fff;
    .desc,
    .editor {
        max-width: 420px;
        line-height: (22 / 15);
        margin: 0 auto;
    }
    .editor {
        display: none;
    }
    &-more {
        margin: 0 auto;
        padding: 3.5em 0 0;
        text-align: center;
        .btn {
            &::before {
                content: '';
                display: inline-block;
                vertical-align: text-bottom;
                background: url('../img/more_arrow.png') no-repeat;
                background-size: contain;
                width: 100 / 15 + 0em;
                height: 22 / 15 + 0em;
                margin-right: 0.5em;
            }
        }
    }
    &.open {
        padding-bottom: 3.5em;
        .desc {
            display: none;
        }
        .editor {
            display: block;
        }
        .about-content-more {
            display: none;
        }
    }
}
.artists-sect {
    .sect-heading-wrap {
        margin-bottom: 3em;
    }
}
.artists-bg {
    position: relative;
    background: url('../img/artists_bg_m.png') no-repeat;
    background-size: 100% 100%;
    margin: -2em -10%;
    padding: 2em 10%;
    .deco {
        display: none;
    }
}
.artists-content {
    padding: 0 0 1em;
    &-inner {
        position: relative;
        z-index: 3;
    }
}
.artists-heading {
    color: #fff;
    text-align: left;
    margin: 0 1em 1.5em;
    @include fz(20px);
    line-height: 1.5;
    span {
        display: block;
    }
}
.workshop-date,
.artists-date,
.timetable-date,
.market-date {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-sans-serif2;
        font-size: 1.07em;
        font-weight: 400;
        width: 4.1em;
        height: 4.1em;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        background: $text-color;
        border: 2px solid $text-color;
        margin: 0 0.3em;
        &.active {
            color: $text-color;
            background-color: #fff;
        }
    }
}
.artists-date {
    margin-bottom: 2em;
}
.artists-time,
.timetable-time {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: auto;
    padding: 0;
    img {
        display: block;
        max-height: 100%;
    }
}
.artists-slider,
.timetable-slider {
    &-wrap {
        position: relative;
        z-index: 5;
        img {
            margin: 0 auto;
        }
    }
    .item {
        padding: 0;
        vertical-align: top;
        img {
            margin: 0 auto;
            &.pc {
                display: none;
            }
            &.m {
                display: block;
            }
        }
    }
}
.no-time {
    .artists-time,
    .timetable-time {
        display: none !important;
    }
    &.artists-slider-wrap,
    &.timetable-slider-wrap {
        padding-left: 0 !important;
    }

}
.timetable-sect {
    background-image: url('../img/timetable_bg.svg');
    background-repeat: no-repeat;
    background-position: 0 100%;
    padding-bottom: 60px;
    .sect-heading-wrap {
        .deco {
            max-height: 4.5em;
            top: 100%;
        }
    }
    .deco {
        display: none;
    }
}
.timetable-content {
    position: relative;
    background: #fff;
    padding: 3.5em 5% 2.5em 8%;
    margin-top: 5em;
}
.timetable-date {
    position: absolute;
    z-index: 2;
    top: -2px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}
.timetable-slider {
    background: url('../img/timetable_slider_bg.png') no-repeat 50% 0;
    background-size: auto 100%;
}
.market-sect {
    .sect-heading-wrap {
        .deco {
            max-height: 5.75em;
            top: 100%;
        }
    }
    .deco {
        width: 64px;
        top: -58px;
        right: percentage($number: 30 / 375);
    }
}
.market-date {
    &-hint {
        text-align: center;
        font-size: 1.25em;
        font-weight: 700;
        margin-bottom: 1em;
    }
    margin-bottom: 2em;
    .btn {
        .week {
            display: none;
        }
    }
}
.market-brand {
    display: none;
    &.active {
        display: block;
    }
}
.market-content {
}
.market-list {
    position: relative;
    z-index: 2;
    margin: 0 -7px;
    @include clearfix;
    .item {
        padding: 0 7px;
        float: left;
        width: 50%;
        &:nth-child(2n+1) {
            clear: left;
        }
    }
}
.market-box {
    text-align: center;
    margin: 0 auto 1.5em;
    .pic {
        margin-bottom: 0.5em;
        position: relative;
        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        a, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
        img {
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .title {
        color: #fff;
        @include fz(16px);
        font-weight: 500;
        line-height: 1.25;
        margin: 0.5em 0 0;
    }
}
.map-sect {
    padding-top: 3em;
    &-inner {
        position: relative;
    }
    .sect-heading-wrap {
        .deco {
            max-height: 3.75em;
            top: 100%;
        }
        .location {
            max-height: 3.5em;
        }
    }
    .container {
        padding-top: 0;
        padding-bottom: 0;
    }
}
.map-content {
    padding: 1em;
}
.map-pic {
    padding: 2.5% 5%;
}
.entry-sect {
    .deco1, .deco2 {
        display: none;
    }
    .deco-m {
        padding-top: 2em;
        text-align: right;
        position: relative;
        bottom: 3em;
    }
}
.entry-content {
    max-width: 1150px;
    margin: 0 auto;
}
.traffic-sect {
    .deco {
        display: none;
    }
}

@media (min-width: $screen-sm-min) {
    .sect-heading {
        &-wrap {
            margin-bottom: 4em;
        }
        img {
            max-height: 3em;
        }
    }
    .banner-sect {
        .scroll {
            display: none;
        }
    }
    .banner-pic {
        &.m {
            display: none;
        }
        &.pc {
            display: block;
        }
    }
    .news-sect {
        .container {
        }
    }
    .news-slider {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 2em;
        .item {
            padding: 0 1em;
        }
    }
    .about-sect {
        padding-bottom: percentage($number: 292 / 1855);
        .deco1 {
            width: percentage($number: 162 / 1855);
            right: percentage($number: 237 / 1855);
            top: 4em;
            transform: none;
        }
        .deco2 {
            display: block;
            width: percentage($number: 494 / 1855);
            left: percentage($number: 138 / 1855);
            bottom: 0;
            padding-bottom: percentage($number: 81 / 1855);
        }
        .deco3 {
            display: block;
            width: percentage($number: 242 / 1855);
            right: percentage($number: 173 / 1855);
            bottom: 0;
            padding-bottom: percentage($number: 104 / 1855);
        }
    }
    .about-content {
        .desc,
        .editor {
            max-width: none;
            padding: 0 2.75em;
        }
        &-more {
            padding: 3.5em 2.75em 0;
            text-align: right;
        }
    }
    .artists-sect {
        z-index: 2;
    }
    .artists-bg {
        background-image: url('../img/artists_bg.png');
        margin-left: -12%;
        margin-right: -12%;
        padding: 12% 4em 15%;
        .deco1, .deco4, .deco5 {
            display: block;
        }
        .deco1 {
            right: 0;
            top: 0;
            width: percentage($number: 506 / 1746);
            transform: translate(#{percentage(23 / 506)}, #{percentage(-44 / 401)});
        }
        .deco4 {
            left: 0;
            bottom: 0;
            width: percentage($number: 447 / 1746);
            transform: translate(#{percentage(-28 / 447)}, #{percentage(24 / 354)});
        }
        .deco5 {
            text-align: right;
            right: 0;
            bottom: 0;
            width: percentage($number: 449 / 1746);
            transform: translate(#{percentage(17 / 449)}, #{percentage(-19 / 216)});
        }
    }
    .artists-date,
    .timetable-date {
        .btn {
            margin: 0 1em;
            font-size: 1.25em;
        }
    }
    .artists-heading {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
    .timetable-sect {
        background-image: none;
        padding-bottom: percentage($number: 240 / 1920);
        .container {
            padding-bottom: 0 !important;
        }
        .deco {
            display: block;
            position: absolute;
            bottom: 0;
            right: percentage($number: 63 / 1859);
            width: percentage($number: 322 / 1859);
            transform: translate(0, #{percentage(-49 / 299)});
        }
    }
    .timetable-content {
        padding: percentage($number: 108 / 1516) percentage($number: 72 / 1516);
    }
    .market-sect {
        .deco {
            display: none;
        }
    }
    .market-date {
        .btn {
            flex-direction: column;
            line-height: 1.2;
            width: 5.2em;
            height: 5.2em;
            .week {
                display: block;
            }
        }
    }
    // .market-date {
    //     position: relative;
    //     margin-bottom: 3em;
    //     .btn {
    //         position: relative;
    //         z-index: 1;
    //         border: 0;
    //         border-radius: 0;
    //         background: #000;
    //         max-width: 30%;
    //         width: auto;
    //         height: 2.8em;
    //         margin: 0 0.75em;
    //         flex: 1;
    //         letter-spacing: 0.1em;
    //         transition: none;
    //         .week {
    //             display: inline-block;
    //             margin-left: 1em;
    //             text-transform: uppercase;
    //         }
    //         &::before {
    //             content: '';
    //             display: none;
    //             background: url('../img/market_date_deco.png') no-repeat;
    //             background-size: contain;
    //             width: 2.5em;
    //             height: 2.5em;
    //             position: absolute;
    //             right: -0.5em;
    //             top: -1em;
    //         }
    //         &.active {
    //             background-color: #fff;
    //             &::before {
    //                 display: block;
    //             }
    //         }
    //     }
    // }
    .market-list {
        margin: 0 -1em;
        .item {
            width: 33.33%;
            padding: 0 1em;
            &:nth-child(2n+1) {
                clear: none;
            }
            &:nth-child(3n+1) {
                clear: left;
            }
        }
    }
    .map-sect {
        .container {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .map-content {
        padding: percentage($number: 67 / 1792);
    }
    .map-pic {
        padding: percentage($number: 40 / 1658) percentage($number: 94 / 1658) percentage($number: 60 / 1658);
    }
    .entry-sect {
        padding: percentage($number: 150 / 1855) 0 percentage($number: 240 / 1855);
        .container {
            padding-top: percentage($number: 82 / 1348) !important;
            padding-bottom: 2.5em !important;
        }
        .deco1, .deco2 {
            display: block;
            position: absolute;
            right: 0;
        }
        .deco1 {
            top: 0;
            width: percentage($number: 333 / 1348);
            transform: translate(#{percentage(178 / 333)}, 0);
        }
        .deco2 {
            bottom: 0;
            width: percentage($number: 450 / 1348);
            transform: translate(#{percentage(137 / 450)}, #{percentage(180 / 316)});
        }
        .deco-m {
            display: none;
        }
    }
    .entry-content {
        padding: 0 2.25em;
    }
}
@media (min-width: $screen-md-min) {
    .sect-heading-wrap {
        font-size: 1.25em;
    }
    .news-box {
        .title {
            background-size: auto 5px;
        }
    }
    .about-content {
        &-more {
        }
    }
    .artists-heading {
        @include fz(30,18);
        span {
            display: inline-block;
        }
    }
    .artists-bg {
        .deco2, .deco3 {
            display: block;
        }
        .deco2 {
            width: percentage($number: 106 / 1746);
            left: 4em;
            top: 50%;
            transform: translateY(-50%);
        }
        .deco3 {
            text-align: right;
            width: percentage($number: 352 / 1746);
            right: 0;
            top: 44%;
            transform: translate(#{percentage(33 / 352)}, -50%);
        }
    }
    .artists-content {
        padding-left: percentage($number: 230 / 1360);
        padding-right: percentage($number: 170 / 1360);
        &-inner {
            max-width: 968px;
            margin: 0 auto;
        }
    }
    .market-date {
        margin-bottom: 3em;
    }
    .market-content {
    }
    .market-list {
        .item {
            width: 25%;
            &:nth-child(3n+1) {
                clear: none;
            }
            &:nth-child(4n+1) {
                clear: left;
            }
        }
    }
    .market-box {
        margin-bottom: 3em;
    }
}
@media (min-width: $screen-lg-min) {
    .sect {
        .container {
            padding: 8em 0;
        }
    }
    .sect-heading {
        img {
            max-height: 3.5em;
        }
    }
    .news-slider {
        .slick-prev,
        .slick-next {
            width: 3.6em;
            height: 3.6em;
            margin-top: -1.8em;
        }
        .slick-prev {
            left: -3.6em;
        }
        .slick-next {
            right: -3.6em;
        }
    }
    .about-content {
        .desc,
        .editor,
        &-more {
            max-width: 1170px;
        }
    }
    .artists-content {
    }
    .artists-date,
    .timetable-date {
        display: none;
    }
    .artists-slider,
    .timetable-slider {
        &-wrap {
            max-width: 1540px;
            margin: 0 auto;
        }
        .item {
            img {
                &.pc {
                    display: block;
                }
                &.m {
                    display: none;
                }
            }
        }
    }
    .timetable-content,
    .market-content {
        margin-left: -6%;
        margin-right: -6%;
    }
    .market-date {
        &-hint {
            @include fz(22,18);
        }
        width: 86%;
        max-width: 1320px;
        margin: 0 auto 4em;
        .btn {
            @include fz(22,18);
            // height: 2.5em;
            // background-image: url('../img/date_bg.png');
        }
    }
    .traffic-sect {
        .deco {
            display: block;
        }
        .deco1 {
            width: percentage($number: 358 / 1625);
            right: percentage($number: 80 / 1625);
            bottom: 100%;
            transform: translate(0, #{percentage(104 / 335)});
        }
        .deco2 {
            width: percentage($number: 233 / 1625);
            right: percentage($number: 185 / 1625);
            top: 100%;
            transform: translate(0, #{percentage(-100 / 173)});
        }
    }
    .traffic-content {
        position: relative;
        margin: 0 -12%;
    }
}
@media (min-width: $screen-xl-min) {
    .news-box {
        .title {
            background-size: auto 8px;
        }
    }
    .artists-sect {
        .deco2 {
            left: 11.5%;
        }
    }
    .artists-bg {
        margin-left: -15%;
        margin-right: -15%;
        padding: 9em 15% 15em;
    }
    .artists-heading {
        @include fz(40,18);
        margin-bottom: 1.5em;
    }
    .market-list {
        max-width: 1530px;
        margin: 0 auto;
        .item {
            width: 16.66%;
            &:nth-child(4n+1) {
                clear: none;
            }
            &:nth-child(6n+1) {
                clear: left;
            }
        }
    }
}
@media (min-width: $screen-xxl-min) {
    .news-slider {
        .slick-prev,
        .slick-next {
            width: 5.5em;
            height: 5.5em;
            margin-top: -2.75em;
        }
        .slick-prev {
            left: -5.5em;
        }
        .slick-next {
            right: -5.5em;
        }
    }
    .timetable-slider {
        &-wrap {
            padding-left: 7.8%;
        }
    }
    .market-date {
        &-hint {
            @include fz(28,18);
        }
        margin-bottom: 5em;
        .btn {
            @include fz(28,18);
            // height: 2.14em;
        }
    }
    .entry-sect {
    }
}
