.editor-embed {
  position: relative;
  margin-bottom: 1.75em;
  &:before {
    content: '';
    display: block;
    padding-top: percentage(9 / 16);
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
.editor-table {
  @include table-reset;
  width: 100%;
  max-width: 100%;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: $line-height-base;
        vertical-align: top;
        text-align: left;
        border-top: 1px solid $table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .editor-table {
    background-color: $body-bg;
  }
}
.table-bordered {
  border: 1px solid $table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $table-border-color;
        vertical-align: top;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}
.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $table-bg-striped;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    background-color: $table-bg-hover;
  }
}
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;
    &.no-border {
      border: 0;
    }

    // Tighten up spacing
    > .editor-table {
      margin-bottom: 0;
      max-width: 1000px;
      min-width: 500px;

      // Ensure the content doesn't wrap
      > thead,
      // > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}
.editor-row {
    .title {
        font-size: 1.2em;
        font-weight: bold;
        color: #222;
    }
    > div {
        margin-bottom: 1.5em;
    }
}
.editor-media-box {
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }
  &,
  .media-body {
    zoom: 1;
    overflow: hidden;
  }

  .media-body {
    width: 100%;
  }

  .media-object {
    display: block;
    max-width: none;

    // Fix collapse in webkit from max-width: 100% and display: table-cell.
    &.img-thumbnail {
      max-width: none;
    }
  }
  .media-left,
  .media-right,
  .media-body {
    display: block;
    vertical-align: top;
  }
  .media-left img {
    max-width: 100%;
  }
  .media-heading {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.editor-img-layout {
  text-align: center;
  margin: 0 -1em;
  .item {
    padding: 1em;
  }
}

@media (max-width: $screen-xs-min) {
    .editor-row {
        > div {
            width: 100%;
        }
    }
}
@media (min-width: $screen-xs-min) {
    .editor-row {
        .col-xs-6:nth-child(2n+1),
        .col-xs-4:nth-child(3n+1),
        .col-xs-3:nth-child(4n+1) {
            clear: left;
        }
    }
}
@media (min-width: $screen-sm-min) {
    .editor-media-box {
      .media-left,
      .media-right,
      .media-body {
        display: table-cell;
        vertical-align: top;
      }
      .media-left img {
        max-width: inherit;
      }
      .media-body {
        width: 10000px;
      }
      .media-right,
      > .pull-right {
        padding-left: 10px;
      }
      .media-left,
      > .pull-left {
        padding-right: 10px;
      }
    }
    .editor-row {
        .col-xs-6:nth-child(2n+1),
        .col-xs-4:nth-child(3n+1),
        .col-xs-3:nth-child(4n+1) {
            clear: none;
        }
        .col-sm-6:nth-child(2n+1),
        .col-sm-4:nth-child(3n+1),
        .col-sm-3:nth-child(4n+1) {
            clear: left;
        }
    }
    .editor-img-layout {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .item {
        max-width: 50%;
      }
      &.x1 {
        .item {
          max-width: 100%;
        }
      }
    }
}
@media (min-width: $screen-md-min) {
    .editor-row {
        .col-sm-6:nth-child(2n+1),
        .col-sm-4:nth-child(3n+1),
        .col-sm-3:nth-child(4n+1) {
            clear: none;
        }
        .col-md-6:nth-child(2n+1),
        .col-md-4:nth-child(3n+1),
        .col-md-3:nth-child(4n+1),
        .col-md-2:nth-child(6n+1) {
            clear: left;
        }
    }
    .editor-img-layout {
      &.x4 {
        .item {
          max-width: 25%;
        }
      }
      &.x3 {
        .item {
          max-width: 33.33%;
        }
      }
      &.x2 {
        .item {
          max-width: 50%;
        }
      }
    }
}
@media (min-width: $screen-lg-min) {
    .editor-row {
        .col-md-6:nth-child(2n+1),
        .col-md-4:nth-child(3n+1),
        .col-md-3:nth-child(4n+1),
        .col-md-2:nth-child(6n+1) {
            clear: none;
        }
        .col-lg-6:nth-child(2n+1),
        .col-lg-4:nth-child(3n+1),
        .col-lg-3:nth-child(4n+1),
        .col-lg-2:nth-child(6n+1) {
            clear: left;
        }
    }
}
