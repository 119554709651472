.header {
    position: fixed;
    z-index: 250;
    right: 0;
    bottom: 20px;
    background: #fff no-repeat;
    border: 2px solid #000;
    border-width: 2px 0 2px 2px;
    width: 45px;
    padding: 6px 4px;
    &-btn {
        @include txt-hide;
        background: url('../img/header_btn.svg') no-repeat;
        background-size: 100% auto;
        width: 40px;
        height: 40px;
        margin: 4px 0;
        &.btn-menu {
            background-position: 0 0;
        }
        &.btn-home {
            background-position: 0 100%;
        }
    }
}
.nav {
    position: fixed;
    z-index: 200;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 100%;
    background: $color2;
    transition: 0.3s;
    .nav-open & {
        right: 0;
    }
    &-open {
        overflow: hidden;
    }
    &-close {
        @include txt-hide;
        position: absolute;
        z-index: 2;
        top: 1.5em;
        right: 1.25em;
        background: url('../img/close.svg') no-repeat 50% 50%;
        background-size: 50% auto;
        width: 2.25em;
        height: 2.25em;
    }
    &-content {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 1em 0;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em 0;
    }
    .menu {
        flex: 100%;
        text-align: center;
        @include fz(26px);
        font-weight: 900;
        margin: auto;
        > li {
            margin: 0.25em 0;
            > a {
                position: relative;
            }
            > ul {
                background: #fff;
                border: solid #000;
                border-width: 3px 0;
                @include fz(20,26);
                padding: 0.25em 1.75em 1em;
                margin-top: 0.25em;
                display: none;
                > li {
                    margin-top: 0.25em;
                    > a {
                        border-bottom: 3px solid #000;
                    }
                }
                > li > a:hover,
                > li.current > a {
                    color: $color2;
                    border-bottom-color: $color2;
                }
            }
        }
        > li.has-child {
            > a {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0.55em;
                    left: 50%;
                    margin-left: 2.25em;
                    background: url('../img/nav_caret.svg') no-repeat;
                    background-size: 100% auto;
                    width: 18 / 26 + 0em;
                    height: 18 / 26 + 0em;
                }
            }
            &.open {
                > a {
                    &::after {
                        background-position: 0 100%;
                    }
                }
            }
        }
        > li > a:hover,
        > li.has-child.open > a,
        > li.current > a {
            color: #fff;
            font-weight: 700;
            // text-shadow: 1px 0 0 #000,
            //     0 1px 0 #000,
            //     -1px 0 0 #000,
            //     0 -1px 0 #000;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .nav {
        &-close {
            top: 2.8em;
            right: 3em;
            background-size: contain;
        }
        &-content {
            margin: percentage($number: 60 / 1920) percentage($number: 64 / 1920);
            padding: 3% 5%;
        }
        .menu {
            > li {
                > ul {
                    background: none;
                    border: 0;
                    padding: 0;
                    > li {
                        > a {
                            display: inline-block;
                            line-height: 1.2;
                        }
                    }
                    > li > a:hover,
                    > li.current > a {
                        color: #fff;
                        border-bottom-color: #fff;
                    }
                }
            }
            > li.has-child.open,
            > li.current {
                > a {
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 50%;
                        margin-right: 2.25em;
                        top: 0.35em;
                        width: 36 / 45 + 0em;
                        height: 49 / 45 + 0em;
                        background: url('../img/nav_deco.svg') no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 0;
        height: 100%;
        bottom: 0;
        width: 65px;
        padding: 0;
        &-btn {
            width: 60px;
            height: 60px;
        }
    }
    .nav {
        &-close {
            top: 3.8em;
            right: 4em;
        }
    }
}
@media (min-width: $screen-xl-min) {
}
@media (min-width: $screen-xxl-min) {
    .nav {
        &-close {
            top: 4.8em;
            right: 5em;
        }
    }
}
